import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierBallonBleu = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierBallonBleu {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { eq: "Ballon Bleu" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Ballon Bleu Watches for Sale'}
      canonical={'/fine-watches/cartier/cartier-ballon-bleu/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Ballon Bleu watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/cartier-ballon-bleu/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-ballon-bleu-header.png"
                alt="Pre-Owned Certified Used Cartier Ballon Bleu Header"
                aria-label="Used Cartier Ballon Bleu Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER BALLON BLEU</h1>
            <h2>
              CARTIER BALLON BLEU: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED CARTIER
              WATCHES{' '}
            </h2>
            <h3>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER WATCHES</h3>

            <p>
              We are a real watch and jewelry store located in Surfside, Miami steps away from Bal
              Harbour Shops, with real watch and jewelry experts including Cartier Watch experts in
              house, six days a week. We are not a pop-up or virtual store. We have a jewelry and
              watch boutique showroom. Our customers say we are #1 Miami used watch buyer and seller
              proudly assisting for the past 42 years.We BUY, SELL, TRADE, AND REPAIR USED AND PRE
              OWNED CARTIER WATCHES including Cartier Ballon Blue watches. Find us in Surfside. We
              also service Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal
              Harbour, Surfside, Sunny Isles , Aventura, Brickell, Pinecrest, Fort Lauderdale and
              many more areas. Online at{' '}
              <a href="/fine-watches/cartier/cartier-ballon-bleu/">www.grayandsons.com</a> or visit
              us in store at our watch and jewelry boutique showroom across from the Bal Harbour
              Shops.
              <br />
              <br />
              People say, “ I want to sell my used Cartier Watch.” If you want to sell your
              pre-owned Luxury Cartier Timepiece, come in to our showroom at Gray and Sons Jewelers
              or go to our watch buying division online at{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
              and we will buy your used Cartier Watch for cash. We will beat all other offers. Come
              see us last. Gray and Sons Jewelers sells certified second hand Cartier
              Watches. Buy your luxury Used Cartier Ballon Bleu Watch with our in house, full time,
              watch specialists. Each Cartier wristwatch purchased at Gray &amp; Sons is Certified
              Pre-Owned and comes with 12 Month Gray &amp; Sons Jewelers Warranty. We handle a vast
              category of PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED, MODERN AND UNUSED
              CARTIER WATCHES. We offer certified pre-owned watches.
              <br />
              <br />
              Make an offer with a watch specialist who is available{' '}
              <a href="/chat-with-viktoria">NOW </a>
              Viktoria is a real person ready to make a deal on the watch of your interest. We offer
              huge Cartier Ballon Bleu selection in stock, view below and on our website. If you
              need a watch repair in Miami, Gray and Sons is a full service repair center including
              Cartier repairs, service and maintenance. We also offer battery replacement for
              Cartier watches. All of our watches have been vetted by Swiss trained watchmakers.
              <br />
              <br />
              Cartier Ballon Bleu watches for men and women are among the most recognizable Cartier
              products. The unique Ballon Bleu watch with a characteristic blue sapphire was for the
              first time presented in 2007. By far, it is Cartier’s best-selling model available in
              many variants, e. g. with a chronograph or tourbillon. Are you thinking of getting
              yourself a prestigious Cartier watch? Gray and Sons is the best place to go for buying
              Cartier Ballon Bleu, selling Cartier Ballon Bleu and repairing Cartier Ballon Bleu.
              Our watches have been recently serviced, freshly lubricated, timing adjusted and
              detailed and come with our exclusive in house one year warranty, and two years
              warranty for modern Rolex watches. If you are looking the most popular Cartier Ballon
              Bleu Watches you can do it by their Ref. Numbers. The most popular are: Reference
              W4BB0024, W69004Z2, Reference Wggbb0030, Reference W3BB009, Reference W69002Z2,
              Reference We9004z3.
              <br />
              <br />
              Waiting lists, unavailable models, and extremely high prices are only some of the
              issues of buying a luxury Cartier wristwatch. Luckily, not for those who know Gray
              &amp; Sons. Keeping customers satisfied for over 40 y ears, and specializing in
              selling luxury jewelry and pre-owned watches, including Cartier Ballon Bleu, Cartier
              Pasha, Cartier Tank, and Cartier Santos. Gray &amp; Sons is known for a vast inventory
              of diamond Cartier watches a nd several Cartier models in yellow gold, white gold, and
              stainless steel.
              <br />
              <br />
              Some of our great Cartier's Ballon Bleu for sale from Gray and Sons inventory include:
              Cartier Ballon Bleu Ref. WE900651. It is a Cartier Ballon Bleu in 18k white gold with
              original double row diamond bezel on an orange alligator strap. Auto w/ sweep seconds
              and date. 36 mm case size. Another special one in our inventory is Cartier Ballon Bleu
              42mm Ref. W6900651. It is a Cartier Ballon Bleu in 18k rose gold on brown alligator
              strap with 18k rose gold deployant buckle. Auto w/ sweep seconds and date. 42 mm case
              size.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierBallonBleu
